:root {
  --muted-text-color: #bec2c5;
  --brand-color: #ff0064;
  --brand-hover-color: #ce2569;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'NittiGrotesk-SemiLight';
  src: url('./fonts/NittiGrotesk-SemiLight.woff2') format('woff2'),
    url('./fonts/NittiGrotesk-SemiLight.woff') format('woff');
  font-weight: 350;
  font-style: normal;
  speak: none;
}

@font-face {
  font-family: 'SuperVario';
  src: url('./fonts/SuperVarioGX.woff2') format('woff2-variations'),
    url('./fonts/SuperVarioGX.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
  speak: none;
}

html {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'NittiGrotesk-SemiLight', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  background-color: #fff;
}

#root {
  position: relative;
}

main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 440px);
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook.hide {
  display: none;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--brand-color);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.show {
  opacity: 1 !important;
}

.top-padding-32 {
  padding: 32px 0 0 0 !important;
}

.space-between {
  justify-content: space-between !important;
}

/* Buttons styles start */
button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 32px 0 0 0;
  text-decoration: none;
  background: var(--brand-color);
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 450ms ease-in-out, transform 350ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  background: var(--brand-hover-color);
}

button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

button:active {
  transform: scale(0.99);
}

.back-btn {
  margin: 16px 0 0 0 !important;
  /*
  position: absolute;
  bottom: 0;
  left: 0;
  */
}

@media screen and (min-width: 1024px) {
  .back-btn {
    /*bottom: -68px;*/
  }
}

/* functional stuff */

.container {
  max-width: 1080px;
  width: 100%;
}

header,
footer {
  background-color: var(--brand-color);
  min-height: 80px;
}
header {
  clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%);
  min-height: 180px;
}

header .clip {
  padding: 32px 0;
}

header div {
  text-align: center;
  margin: 0 auto;
}

header .logo-container {
  display: inline-block;
  width: 180px;
  max-width: 180px;
  height: auto;
}

header div svg {
  display: inline-block;
  margin: 0 auto;
}

#logo {
  max-width: 170px;
  height: auto;
  cursor: pointer;
  /* margin-top: 24px; */
}

#records {
  max-width: 180px;
  height: auto;
}

.artists {
  opacity: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 320px;
  transition: opacity 0.4s linear;
}

.artist-item {
  position: relative;
  width: 100%;
  padding: 32px 0;
}

.artist-item .distortion {
  cursor: pointer;
  width: 256px;
  height: 329px;
  /* box-shadow: 0 0 0 10px white, 0 0 6px 10px rgba(0, 0, 0, 0.15); */
}

.artist-item .artist-heading {
  position: absolute;
  bottom: 32px;
  left: 0;
  right: 0;
  padding: 12px 0;
  text-align: center;
  background-color: white;
  color: black;
  font-size: 1.6rem;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'SuperVario';
  font-weight: 520;
  mix-blend-mode: screen;
}

.artist-item img {
  width: 100%;
  height: auto;
}

.description {
  padding: 0 32px;
  max-width: 1080px;
  margin: 56px auto 0;
}

.description strong {
  text-transform: uppercase;
  font-family: 'SuperVario';
  font-weight: bolder;
}

footer {
  position: relative;
  clip-path: polygon(0 40%, 100% 0%, 100% 100%, 0% 100%);
  width: 100%;
  min-height: 260px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  padding: 48px 0;
}

.footer > p {
  margin: 0;
  padding: 0;
  text-align: center;
}

.footer small {
  display: block;
  text-align: center;
  color: #fff;
}

.footer small a {
  color: #fff;
  text-decoration: none;
}

.footer nav {
  height: 100%;
  margin-top: 0;
  align-self: center;
  margin-top: 12px;
}

.footer-nav {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: inherit;
  max-width: 1080px;
}

.footer-nav li {
  margin: 0 12px;
  text-align: center;
}

.footer-nav li a {
  color: #fff;
  text-decoration: none;
}

.artist-details {
  opacity: 0;
  margin-top: 48px;
  padding: 0 12px;
  transition: opacity 0.4s linear;
  min-height: calc(100vh - 288px);
}

.artist-details h1 {
  padding: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: 'SuperVario';
  font-weight: 520;
}

.artist-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}

.artist-content .images {
  font-size: 0;
  margin-bottom: 32px;
}

.artist-content .images img {
  width: 100%;
  height: auto;
}

.artist-content .bio {
  position: relative;
}

.artist-content .bio p {
  margin: 0;
  text-align: justify;
  line-height: 1.22;
  font-size: large;
}

.artist-content .bio a {
  font-weight: bold;
  color: var(--brand-color);
  text-decoration: none;
}

.artist-content .bio a:hover {
  color: var(--brand-hover-color);
}

@media screen and (min-width: 768px) {
  .artists {
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    max-width: 640px;
  }

  .artist-content {
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .artist-content .images img {
    width: 100%;
    height: auto;
  }

  /* footer {
    min-height: 180px;
  }

  .footer > p {
    margin: 18px 0 0 0;
  } */
}

.socials li svg {
  fill: var(--brand-color);
  stroke: var(--brand-color);
}

.artist-content .socials li {
  font-size: 32px;
  margin: 12px 0 0 0;
}

@media screen and (min-width: 1180px) {
  html {
    box-sizing: inherit;
    /* overflow: hidden; */
  }
  body {
    height: 100vh;
  }

  #root {
    height: 100vh;
  }

  main {
    min-height: calc(100vh - 240px);
  }

  header {
    min-height: initial;
    max-height: 120px;
  }

  header div {
    text-align: left;
  }
  header .clip {
    padding: 24px 0;
  }

  footer {
    min-height: 120px;
  }

  .footer > p {
    margin: 24px 0 0 0;
  }

  .footer small {
    display: inline-block;
    padding: 0 6px;
  }

  #logo {
    /* margin-top: 48px; */
  }

  .artists {
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 972px;
  }

  .artist-item .distortion {
    width: 256px;
    height: 329px;
  }

  .artist-details h1 {
    max-width: 1080px;
    margin: 0 auto;
  }

  .artist-content {
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1080px;
    margin: 0 auto;
  }

  .artist-content .images {
    flex: 1 1 0;
  }
  .artist-content .bio {
    flex: 1 1 0;
    max-width: 460px;
  }

  .socials li svg {
    fill: var(--brand-color);
    stroke: var(--brand-color);
  }

  .artist-content .socials {
    display: flex;
    flex-flow: row nowrap;
    margin: 12px 0 0 0;
  }

  .artist-content .socials li {
    font-size: 32px;
    margin: 0 12px 0 0;
  }

  .artist-content .socials li:last-child {
    margin: 0;
  }

  .artist-content .images img {
    width: 100%;
    height: auto;
    padding-right: 22px;
  }

  .description {
    text-align: center;
    max-width: 800px;
  }

  /* footer {
    position: absolute;
    bottom: 0;
  } */

  .footer {
    padding: 0;
    bottom: 16px;
  }

  .footer-nav {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 1180px) and (max-height: 820px) {
  .artists {
    max-width: 960px;
  }

  .artist-item .distortion {
    width: 196px;
    height: 251px;
  }

  .artist-content .images {
    flex: 0 1 44%;
  }
  .artist-content .bio {
    flex: 1 1 0;
    max-width: 660px;
  }

  .artist-content .bio p {
    font-size: medium;
  }

  .artist-content .images img {
    width: 100%;
    height: auto;
    padding-right: 22px;
  }

  .description {
    text-align: center;
    max-width: 800px;
    padding: 0 24px;
    margin: 32px auto 0;
  }
  .lds-facebook {
    margin: 32px auto 0;
  }
}

@media screen and (min-width: 1180px) and (max-height: 720px) {
  .artist-content .images {
    flex: 0 1 38%;
  }
  .artist-content .bio {
    flex: 1 1 0;
    max-width: 660px;
  }
}

@media screen and (min-width: 1440px) {
}

@media screen and (min-width: 1080px) and (min-height: 768px) {
  .artists {
    margin-top: 0;
  }
  .description,
  .lds-facebook {
    margin: 32px auto 0;
  }
  main {
    min-height: calc(100vh - 440px);
  }
}

@media screen and (min-width: 1180px) and (min-height: 768px) {
  main {
    min-height: calc(100vh - 240px);
  }
}

@media screen and (min-width: 1080px) and (min-height: 900px) {
  .artists {
    margin-top: 32px;
  }
  .description,
  .lds-facebook {
    margin: 64px auto 0;
  }
  main {
    min-height: calc(100vh - 440px);
  }
}

@media screen and (min-width: 1180px) and (min-height: 900px) {
  main {
    min-height: calc(100vh - 240px);
  }
}

@media screen and (min-width: 1080px) and (min-height: 1000px) {
  .artists {
    margin-top: 64px;
  }
  .description,
  .lds-facebook {
    margin: 80px auto 0;
  }
  main {
    min-height: calc(100vh - 440px);
  }
}

@media screen and (min-width: 1180px) and (min-height: 1000px) {
  main {
    min-height: calc(100vh - 240px);
  }
}
